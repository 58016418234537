<div class="parent-form" [formGroup]="form">
  <div class="form-fields-group">
    @if (mode() === 'write' || mode() === 'onlyName') {
      <wlcm-form-field>
        <wlcm-label>Name</wlcm-label>

        <input wlcmInput formControlName="full_name" placeholder="Type here" />
      </wlcm-form-field>
    } @else {
      <wlcm-form-field>
        <wlcm-label>Name</wlcm-label>

        <wlcm-select
          wlcmInput
          formControlName="full_name"
          napaTrackReactiveControl
          [options]="parentOptions()"
          (selectionChange)="handleSelectedParent($event)"
          selectOptionFormat="CompleteOption"
        ></wlcm-select>
      </wlcm-form-field>
    }
  </div>

  <h4>Contact info</h4>

  <div class="form-fields-group">
    <wlcm-form-field>
      <wlcm-label>Email</wlcm-label>

      <input wlcmInput formControlName="email" placeholder="Type here" />
    </wlcm-form-field>

    <wlcm-form-field>
      <wlcm-label>Phone number</wlcm-label>

      <wlcm-phone-input-container wlcmInput>
        <input
          wlcmPhoneInput
          wlcmPhoneInputMask="(000) 000-0000"
          placeholder="(000) 000-0000"
          formControlName="phone"
        />
      </wlcm-phone-input-container>

      <span wlcmFormFieldPrefix inlineSVG="assets/icons/phone-gray.svg"></span>
    </wlcm-form-field>
  </div>

  <wlcm-form-field>
    <wlcm-label>Primary Address</wlcm-label>

    <wlcm-autocomplete
      wlcmInput
      formControlName="address"
      [fetchPaginatedOptions]="getPlaces"
      [noResultsTemplate]="noResultsTemplate"
      selectOptionFormat="CompleteOption"
      placeholder="Type here"
    ></wlcm-autocomplete>

    <ng-template #noResultsTemplate let-value>
      @if (!value) {
        Please enter at least one letter
      } @else {
        No results found
      }
    </ng-template>
  </wlcm-form-field>

  @if (mode() === 'write') {
    <wlcm-form-field *napaCanRender="AdminRoles">
      <wlcm-label>Notes</wlcm-label>
      <textarea wlcmInput formControlName="notes" placeholder="Type here"></textarea>
    </wlcm-form-field>
  }

  @if (mode() === 'write') {
    <wlcm-form-field *napaCanRender="AdminRoles">
      <wlcm-label>Photo Release</wlcm-label>

      <wlcm-select
        wlcmInput
        formControlName="photo_release"
        napaTrackReactiveControl
        [options]="photoReleaseOptions"
      ></wlcm-select>
    </wlcm-form-field>
  }
</div>
